import React, { useEffect, useRef, useState } from 'react'
import './index.css'
import { brandColor } from '../../style/brandConfig'
import Slider from 'react-slick'
import { useLocation, useNavigate } from 'react-router-dom'
import Button from '../../common/buttons/Button'
import useAuth from '../../utils/hooks/useAuth'
import { RequestSource, useForgotPasswordMutation } from '../../graphql'
import { useSelector } from 'react-redux'
import { selectBrandData, selectWindowSize } from '../../store/base/commonSlice'
import { WindowSize } from '../../constants/enums'

const SignIn = () => {
  const location = useLocation()
  const redirectUrl = location.state?.redirectUrl
  const windowSize = useSelector(selectWindowSize)
  const brand = useSelector(selectBrandData)

  let signInSlider = useRef<Slider>(null)
  let resetPasswordSlider = useRef<Slider>(null)

  const navigate = useNavigate()

  const [brandSrc, setBrandSrc] = useState<Link | undefined>(undefined)

  useEffect(() => {
    if (brand) {
      setBrandSrc(brand)
    }
  }, [brand])

  const [type, setType] = useState('sign-in')
  const [signInEmailAddress, _setSignInEmailAddress] = useState('')
  const [signInPassword, _setSignInPassword] = useState('')
  const [invalidSignInEmailAddress, setInvalidSignInEmailAddress] = useState(false)
  const [invalidSignInPassword, setInvalidSignInPassword] = useState<string | null>(null)
  const [btnHoverBackgroundColor, setBtnHoverBackgroundColor] = useState('transparent')
  const [slideNumber, setSlideNumber] = useState(1)
  const [isSubmitting, setSubmitting] = useState(false)

  const { signIn } = useAuth()

  const emailInputRef = useRef<HTMLInputElement>(null)
  const passwordInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    emailInputRef.current?.focus()
  }, [])

  const onSignIn = async () => {
    if (validateSignInPassword()) {
      setSubmitting(true)
      const result = await signIn({ email: signInEmailAddress, password: signInPassword, keepSignedIn: true })
      setSubmitting(false)
      if (result.status === 'failed') {
        setInvalidSignInPassword(result.message)
      } else {
        navigate(redirectUrl ? redirectUrl : '/')
      }
    }
  }

  const setSignInEmailAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    _setSignInEmailAddress(e.target.value)
    if (e.target.value && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)) {
      setInvalidSignInEmailAddress(false)
    }
  }

  const validateSignInEmailAddress = () => {
    if (signInEmailAddress && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(signInEmailAddress)) {
      setInvalidSignInEmailAddress(false)
      return true
    } else {
      setInvalidSignInEmailAddress(true)
      return false
    }
  }

  const goBackToEmailSlide = () => {
    signInSlider?.current?.slickPrev()
    setSlideNumber(1)
    emailInputRef.current?.focus()
  }

  const advanceToPasswordSlide = () => {
    if (validateSignInEmailAddress()) {
      signInSlider?.current?.slickNext()
      setSlideNumber(2)
      passwordInputRef.current?.focus()
    }
  }

  const setSignInPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    _setSignInPassword(e.target.value)
    if (e.target.value) {
      setInvalidSignInPassword(null)
    }
  }

  const validateSignInPassword = () => {
    if (signInPassword) {
      setInvalidSignInPassword(null)
      return true
    } else {
      setInvalidSignInPassword('Enter a valid password')
      return false
    }
  }

  const [forgotPasswordMutation] = useForgotPasswordMutation()

  const forgotPassword = () => {
    let { protocol, hostname, port } = window.location
    forgotPasswordMutation({
      variables: {
        data: {
          email: signInEmailAddress,
          protocol,
          hostname,
          port: hostname === 'localhost' ? port : undefined,
          source: RequestSource.Ott,
        },
      },
    })
    setType('reset-password')
  }

  const signInFirstSlide = () => {
    return (
      <div style={styles.signInSlide}>
        <div style={styles.signInHeaderContainer}>
          <span style={styles.signInHeader}>SIGN IN</span>
          <div style={styles.signInHeaderDivider} />
        </div>
        <div style={styles.signInInputForm}>
          <span style={styles.signInFormHeader}>Sign in with your email</span>
          <div style={styles.signInInputContainer}>
            <input
              type="text"
              id="signInEmailAddress"
              name="signInEmailAddress"
              autoComplete={'off'}
              placeholder={'Email Address'}
              required
              value={signInEmailAddress}
              onBlur={validateSignInEmailAddress}
              onChange={setSignInEmailAddress}
              onKeyDown={(e) => e.key === 'Enter' && advanceToPasswordSlide()}
              style={{
                ...styles.signInInput,
                border: `3px solid ${invalidSignInEmailAddress ? '#FF4848' : 'transparent'}`,
              }}
              tabIndex={slideNumber === 1 ? undefined : -1}
              ref={emailInputRef}
            />
            {invalidSignInEmailAddress && <span style={styles.signInErrorText}>Enter a valid email address.</span>}
          </div>
          <div style={{ marginTop: 50 }}>
            <Button
              text={'continue'}
              width={'100%'}
              height={36}
              arrowRight={true}
              disabled={!signInEmailAddress || invalidSignInEmailAddress}
              onClick={advanceToPasswordSlide}
            />
          </div>
          <span style={styles.signInMiscText}>
            New to {brandSrc?.text}?{' '}
            <a
              style={{ ...styles.signInLink, marginLeft: 4 }}
              onClick={() => navigate('/registration', { state: { redirectUrl: redirectUrl || '/' } })}>
              Register
            </a>
          </span>
        </div>
      </div>
    )
  }

  const signInSecondSlide = () => {
    return (
      <div style={styles.signInSlide}>
        <div style={styles.signInHeaderContainer}>
          <span style={styles.signInHeader}>SIGN IN</span>
          <div style={styles.signInHeaderDivider} />
        </div>
        <div style={styles.signInInputForm}>
          <span style={styles.signInFormHeader}>Enter your password</span>
          <div style={styles.signInInputContainer}>
            <input
              type="password"
              id="signInPassword"
              name="signInPassword"
              autoComplete={'off'}
              placeholder={'Password'}
              required
              value={signInPassword}
              onBlur={validateSignInPassword}
              onChange={setSignInPassword}
              onKeyDown={(e) => e.key === 'Enter' && onSignIn()}
              style={{
                ...styles.signInInput,
                border: `3px solid ${invalidSignInPassword ? '#FF4848' : 'transparent'}`,
              }}
              tabIndex={slideNumber === 2 ? undefined : -1}
              ref={passwordInputRef}
            />
            {invalidSignInPassword && <span style={styles.signInErrorText}>{invalidSignInPassword || ''}</span>}
          </div>
          <div style={{ marginTop: 50 }}>
            <Button
              text={isSubmitting ? 'signing in...' : 'sign in'}
              width={'100%'}
              height={36}
              backgroundColor={brandColor}
              disabled={!signInPassword || !!invalidSignInPassword || isSubmitting}
              onClick={onSignIn}
            />
          </div>
          <div style={{ marginTop: 25 }}>
            <Button
              text={'back to email'}
              width={'100%'}
              height={36}
              backgroundColor={brandColor}
              disabled={isSubmitting}
              onClick={goBackToEmailSlide}
            />
          </div>
          <span style={styles.signInMiscText}>
            <a style={{ ...styles.signInLink, marginLeft: 4 }} onClick={forgotPassword}>
              Forgot Password?
            </a>
          </span>
        </div>
      </div>
    )
  }

  const resetPassword = () => {
    return (
      <div style={styles.signInSlide}>
        <div style={styles.signInHeaderContainer}>
          <span style={styles.signInHeader}>RESET PASSWORD</span>
          <div style={styles.signInHeaderDivider} />
        </div>
        <div style={styles.signInInputForm}>
          <span style={styles.signInFormHeader}>Check your email</span>
          <span style={{ ...styles.signInMiscText, marginTop: 10 }}>
            If you have an account with us, we&apos;ve sent you link to reset your password.
          </span>
          <button
            style={{
              ...styles.signInBtn,
              border: `3px solid ${brandColor}`,
              marginTop: 80,
              background: btnHoverBackgroundColor,
            }}
            onMouseOver={() =>
              setBtnHoverBackgroundColor(`rgb(${brandColorRgb?.r}, ${brandColorRgb?.g}, ${brandColorRgb?.b}, 0.3)`)
            }
            onMouseLeave={() => setBtnHoverBackgroundColor('transparent')}
            className={'signInBtn'}
            onClick={() => setType('sign-in')}>
            <span style={styles.signInBtnText}>
              <div style={styles.btnLeftArrow} />
              Back to sign in
            </span>
          </button>
        </div>
      </div>
    )
  }

  const signInSettings = {
    dots: true,
    infinite: false,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplay: false,
    draggable: false,
    swipe: false,
    variableWidth: false,
    adaptiveHeight: false,
    nextArrow: <></>,
    prevArrow: <></>,
  }

  const resetPasswordSettings = {
    dots: false,
    infinite: false,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplay: false,
    draggable: false,
    swipe: false,
    variableWidth: false,
    adaptiveHeight: false,
    nextArrow: <></>,
    prevArrow: <></>,
  }

  const hexToRgb = (hex: string) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null
  }

  const brandColorRgb = hexToRgb(brandColor)
  const brandStyle = windowSize === WindowSize.Mobile ? { ...styles.brand, ...styles.mobileBrand } : styles.brand

  return (
    <div className={'signInWrapper'} style={styles.container}>
      {brandSrc ? (
        <div style={brandStyle}>
          <img src={brandSrc?.icon?.src} alt="brand" style={styles.logo} onClick={() => navigate('/')} />
        </div>
      ) : null}
      <div
        style={{
          maxWidth: 370,
          width: '100%',
          height: '100%',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 25,
          marginBottom: 25,
        }}>
        {type === 'sign-in' ? (
          <Slider ref={signInSlider} {...signInSettings}>
            {signInFirstSlide()}
            {signInSecondSlide()}
          </Slider>
        ) : type === 'reset-password' ? (
          <Slider ref={resetPasswordSlider} {...resetPasswordSettings}>
            {resetPassword()}
          </Slider>
        ) : null}
      </div>
    </div>
  )
}

const styles = {
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: 35,
    paddingRight: 35,
    paddingTop: 17,
    height: 'calc(100vh - 17px)',
  } as React.CSSProperties,
  logo: {
    height: process.env.REACT_APP_BRAND_HEIGHT || '100%',
    cursor: 'pointer',
  } as React.CSSProperties,
  brand: {
    height: 70,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  } as React.CSSProperties,
  mobileBrand: {
    height: 40,
  } as React.CSSProperties,
  signInSlide: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  } as React.CSSProperties,
  signInHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    marginBottom: 50,
  } as React.CSSProperties,
  signInHeader: {
    color: '#FFFFFF',
    marginRight: 15,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 2.8,
    textTransform: 'uppercase',
    opacity: 1,
    whiteSpace: 'nowrap',
  } as React.CSSProperties,
  signInHeaderDivider: {
    backgroundColor: '#404040',
    width: '100%',
    height: 1,
  } as React.CSSProperties,
  signInInputForm: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  } as React.CSSProperties,
  signInFormHeader: {
    fontSize: 24,
    color: '#FFFFFF',
    fontWeight: 700,
    width: '100%',
    textAlign: 'left',
    marginBottom: 15,
    display: 'flex',
    alignItems: 'center',
  } as React.CSSProperties,
  signInInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 15,
    position: 'relative',
  } as React.CSSProperties,
  signInInput: {
    width: '100%',
    boxSizing: 'border-box',
    height: 44,
    fontSize: 19,
    borderRadius: 5,
    border: 'none',
    padding: '0px 20px',
    marginTop: 15,
    background: 'rgba(255, 255, 255, 0.1)',
  } as React.CSSProperties,
  signInLink: {
    borderBottom: '1px solid #FFFFFF',
    fontWeight: 500,
    color: '#FFFFFF',
    cursor: 'pointer',
  } as React.CSSProperties,
  signInBtnText: {
    fontSize: 14,
    letterSpacing: 1.4,
    color: '#FFFFFF',
    opacity: 1,
    fontWeight: 600,
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  } as React.CSSProperties,
  signInMiscText: {
    textAlign: 'left',
    fontSize: 17,
    letterSpacing: 0,
    color: '#FFFFFF',
    opacity: 1,
    marginTop: 25,
    display: 'flex',
    alignItems: 'center',
  } as React.CSSProperties,
  signInErrorText: {
    textAlign: 'left',
    fontSize: 13,
    fontWeight: 500,
    letterSpacing: 0,
    color: '#FF4848',
    opacity: 1,
    marginTop: 8,
    width: '100%',
    position: 'absolute',
    bottom: -19,
    left: 0,
  } as React.CSSProperties,
  signInBtn: {
    height: 39,
    opacity: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'all .3s ease-in-out',
  } as React.CSSProperties,
  btnRightArrow: {
    border: 'solid white',
    borderWidth: '0px 3px 3px 0px',
    display: 'inline-block',
    padding: 2.75,
    marginLeft: 4,
    transform: 'rotate(-45deg)',
  } as React.CSSProperties,
  btnLeftArrow: {
    border: 'solid white',
    borderWidth: '0px 3px 3px 0px',
    display: 'inline-block',
    padding: 2.75,
    marginRight: 4,
    transform: 'rotate(135deg)',
  } as React.CSSProperties,
}

export default SignIn
