import React from 'react'
import {
  MediaItemDocument,
  MediaItemFieldsFragment,
  useAddToWatchlistMutation,
  useRemoveFromWatchlistMutation,
} from '../../graphql'
import RoundButton from '../buttons/RoundButton/RoundButton'

type WatchlistButtonProps = {
  mediaItem?: MediaItemFieldsFragment | null
}
const WatchlistButton = ({ mediaItem }: WatchlistButtonProps) => {
  const [addToWatchlist, { client }] = useAddToWatchlistMutation({
    variables: {
      data: {
        contentId: mediaItem?.id || '',
      },
    },
    onCompleted: () => {
      client.writeQuery({
        query: MediaItemDocument,
        data: {
          mediaItem: {
            ...mediaItem,
            favorited: true,
          },
        },
        variables: {
          id: mediaItem?.id,
        },
      })
    },
  })

  const [removeFromWatchlist] = useRemoveFromWatchlistMutation({
    variables: {
      data: {
        contentId: mediaItem?.id || '',
      },
    },
    onCompleted: () => {
      client.writeQuery({
        query: MediaItemDocument,
        data: {
          mediaItem: {
            ...mediaItem,
            favorited: false,
          },
        },
        variables: {
          id: mediaItem?.id,
        },
      })
    },
  })

  const handleOnClick = async () => {
    if (!mediaItem?.favorited) {
      try {
        await addToWatchlist()
      } catch (error) {
        console.error('Error adding to watchlist', error)
      }
    } else {
      try {
        await removeFromWatchlist()
      } catch (error) {
        console.error('Error removing from watchlist', error)
      }
    }
  }

  return <RoundButton type="watchlist" onClick={handleOnClick} favorited={mediaItem?.favorited} />
}

export default WatchlistButton
