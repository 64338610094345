import React, { useEffect, useRef, useState } from 'react'
import { WindowSize } from '../constants/enums'
import { useSelector } from 'react-redux'
import { selectBrandData, selectWindowSize } from '../store/base/commonSlice'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Button from '../common/buttons/Button'
import { brandColor } from '../style/brandConfig'
import { useResetPasswordMutation } from '../graphql'
import { ApolloError } from '@apollo/client'

const ResetPassword = () => {
  const windowSize = useSelector(selectWindowSize)
  const brand = useSelector(selectBrandData)
  const passwordInputRef = useRef<HTMLInputElement>(null)
  const [searchParams] = useSearchParams()

  const navigate = useNavigate()

  const [password, _setPassword] = useState('')
  const [confirmPassword, _setConfirmPassword] = useState('')
  const [invalidPassword, setInvalidPassword] = useState<string | null>(null)
  const [invalidConfirmPassword, setInvalidConfirmPassword] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)

  const [resetPasswordMutation] = useResetPasswordMutation()

  useEffect(() => {
    passwordInputRef.current?.focus()
  }, [])

  const setPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    _setPassword(e.target.value)
    if (e.target.value && e.target.value.length >= 6) {
      setInvalidPassword(null)
    }
  }
  const validatePassword = () => {
    if (password && password.length >= 6) {
      setInvalidPassword(null)
      return true
    } else {
      setInvalidPassword('Password must contain at least six digits')
      return false
    }
  }

  const setConfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    _setConfirmPassword(e.target.value)
    if (e.target.value && e.target.value === password) {
      setInvalidConfirmPassword(false)
    }
  }
  const validateConfirmPassword = () => {
    if (password && confirmPassword && password === confirmPassword) {
      setInvalidConfirmPassword(false)
      return true
    } else {
      setInvalidConfirmPassword(true)
      return false
    }
  }

  const handleOnRegister = async () => {
    const token = searchParams.get('token')?.replaceAll(' ', '+')
    if (token && validatePassword() && validateConfirmPassword()) {
      setSubmitting(true)
      try {
        await resetPasswordMutation({
          variables: {
            data: {
              password,
              token,
            },
          },
        })
        navigate('/sign-in')
      } catch (e) {
        const error = e as ApolloError
        setInvalidPassword(error.message)
      }
      setSubmitting(false)
    }
  }

  const brandStyle = windowSize === WindowSize.Mobile ? { ...styles.brand, ...styles.mobileBrand } : styles.brand

  return (
    <div className={'resetPasswordContainer'} style={styles.container}>
      <div style={brandStyle}>
        <img src={brand?.icon?.src} alt="brand" style={styles.logo} onClick={() => navigate('/')} />
      </div>
      <div style={styles.signInSlide}>
        <div style={styles.signInHeaderContainer}>
          <span style={styles.signInHeader}>RESET PASSWORD</span>
          <div style={styles.signInHeaderDivider} />
        </div>
        <div style={styles.registrationInputForm}>
          <span style={styles.registrationFormHeader}>Create a password</span>
          <div style={{ ...styles.registrationInputContainer, ...styles.registrationPasswordInputContainer }}>
            <label style={styles.registrationInputLabel}>Enter a New Password</label>
            <input
              type="password"
              id="password"
              name="password"
              autoComplete={'off'}
              placeholder={'At least six digits'}
              required
              value={password}
              onKeyDown={(e) => e.key === 'Enter' && handleOnRegister()}
              onBlur={validatePassword}
              onChange={setPassword}
              style={{
                ...styles.registrationInput,
                border: `3px solid ${invalidPassword ? '#FF4848' : 'transparent'}`,
              }}
              ref={passwordInputRef}
            />
            {invalidPassword && <span style={styles.registrationErrorText}>{invalidPassword}</span>}
          </div>
          <div
            style={{
              ...styles.registrationInputContainer,
              ...styles.registrationPasswordInputContainer,
              marginTop: 10,
            }}>
            <label style={styles.registrationInputLabel}>Confirm Password</label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              autoComplete={'off'}
              required
              value={confirmPassword}
              onKeyDown={(e) => e.key === 'Enter' && handleOnRegister()}
              onBlur={validateConfirmPassword}
              onChange={setConfirmPassword}
              style={{
                ...styles.registrationInput,
                border: `3px solid ${invalidConfirmPassword ? '#FF4848' : 'transparent'}`,
              }}
            />
            {invalidConfirmPassword && <span style={styles.registrationErrorText}>Passwords must match.</span>}
          </div>
          <div style={{ marginTop: 35 }}>
            <Button
              text={'reset password'}
              width={'100%'}
              backgroundColor={brandColor}
              height={36}
              disabled={
                !password ||
                !confirmPassword ||
                !!invalidPassword ||
                invalidConfirmPassword ||
                password !== confirmPassword ||
                isSubmitting
              }
              onClick={handleOnRegister}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const styles = {
  container: {
    width: '100vw',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  } as React.CSSProperties,
  logoWrapper: {
    display: 'flex',
    marginLeft: 40,
    marginTop: 20,
    position: 'absolute',
    top: 0,
    left: 0,
    height: 65,
    width: 65,
  } as React.CSSProperties,
  brand: {
    height: 70,
    display: 'flex',
    position: 'absolute',
    top: 0,
    left: 0,
    marginTop: 17,
    marginLeft: 35,
    cursor: 'pointer',
    alignItems: 'center',
  } as React.CSSProperties,
  mobileBrand: {
    display: 'flex',
    height: 40,
  } as React.CSSProperties,
  logo: {
    height: process.env.REACT_APP_BRAND_HEIGHT || '100%',
    cursor: 'pointer',
  } as React.CSSProperties,
  signInSlide: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 350,
  } as React.CSSProperties,
  signInHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    marginBottom: 50,
  } as React.CSSProperties,
  signInHeader: {
    color: '#FFFFFF',
    marginRight: 15,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 2.8,
    textTransform: 'uppercase',
    opacity: 1,
    whiteSpace: 'nowrap',
  } as React.CSSProperties,
  signInHeaderDivider: {
    backgroundColor: '#404040',
    width: '100%',
    height: 1,
  } as React.CSSProperties,
  registrationInputForm: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  } as React.CSSProperties,
  registrationFormHeader: {
    fontSize: 24,
    color: '#FFFFFF',
    fontWeight: 700,
    width: '100%',
    textAlign: 'left',
    marginBottom: 15,
    display: 'flex',
    alignItems: 'center',
  } as React.CSSProperties,
  registrationInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 15,
    position: 'relative',
  } as React.CSSProperties,
  registrationPasswordInputContainer: {
    marginTop: 15,
    marginBottom: 35,
  } as React.CSSProperties,
  registrationInputLabel: {
    fontSize: 20,
    color: '#FFFFFF',
    fontWeight: 700,
    width: '100%',
    textAlign: 'left',
  } as React.CSSProperties,
  registrationInput: {
    width: 304,
    height: 44,
    fontSize: 19,
    borderRadius: 5,
    border: 'none',
    padding: '0px 20px',
    marginTop: 15,
    background: 'rgba(255, 255, 255, 0.1)',
  } as React.CSSProperties,
  registrationErrorText: {
    textAlign: 'left',
    fontSize: 13,
    fontWeight: 500,
    letterSpacing: 0,
    color: '#FF4848',
    opacity: 1,
    marginTop: 8,
    width: '100%',
    position: 'absolute',
    bottom: -19,
    left: 0,
  } as React.CSSProperties,
}

export default ResetPassword
