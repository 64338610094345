import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isMobile } from 'react-device-detect'
import { ContentRestrictionsType, MediaItemFieldsFragment, usePlayerLazyQuery } from '../../graphql'
import RegistrationWall from './RegistrationWall'
import PpvPaywall from './PpvPaywall'
import SubscriptionPaywall from './SubscriptionPaywall'
import WatchlistButton from './WatchlistButton'
import RoundButton from '../../common/buttons/RoundButton/RoundButton'
import Button from '../../common/buttons/Button'
import { brandColor } from '../../style/brandConfig'
import { playVideo, stopVideo } from '../../store/video/playerSlice'
import { selectSession } from '../../store/auth/sessionSlice'
import useLiveNow from '../../utils/hooks/useLiveNow'

type ActionButtonsProps = {
  mediaItem: MediaItemFieldsFragment | null | undefined
  progress: number
  onSlider?: boolean
}

const ActionButtons = ({ mediaItem, progress, onSlider }: ActionButtonsProps) => {
  const { signedIn } = useSelector(selectSession)
  const dispatch = useDispatch()

  const [fetchPlayer] = usePlayerLazyQuery({
    fetchPolicy: 'network-only',
  })

  let upcoming = false
  const now = new Date()
  if (mediaItem?.__typename === 'Event') {
    const start = new Date(mediaItem.scheduledStart)
    //const end = new Date(mediaItem.scheduledEnd)
    if (now < start) {
      upcoming = true
    }
  }
  if (mediaItem?.publishOn) {
    const start = new Date(mediaItem?.publishOn)
    if (now < start) {
      upcoming = true
    }
  }

  // Todo: Come up with a better way to determine completeness that isn't based on a percentage but rather some number of seconds from the end
  const completed = progress >= 0.995
  const liveNow = useLiveNow(mediaItem?.id || null)

  return (
    <>
      {mediaItem?.restrictions?.length ? (
        <>
          {mediaItem.restrictions.includes(ContentRestrictionsType.Registration) && (
            <RegistrationWall hideSignIn={onSlider} />
          )}
          {(mediaItem.restrictions.includes(ContentRestrictionsType.Ppv) ||
            mediaItem.restrictions.includes(ContentRestrictionsType.Bundle)) && (
            <PpvPaywall contentId={mediaItem?.id} />
          )}
          {mediaItem.restrictions.includes(ContentRestrictionsType.Subscription) && (
            <SubscriptionPaywall contentId={mediaItem?.id} />
          )}
        </>
      ) : (
        !upcoming && (
          <Button
            text={progress && !completed ? 'resume' : 'play'}
            width={progress && !completed ? 118 : 96}
            height={36}
            backgroundColor={brandColor}
            icon={true}
            onClick={async () => {
              if (mediaItem?.id) {
                if (isMobile) {
                  // must stop video for resetting it on iOS safari
                  dispatch(stopVideo())
                }
                const player = await fetchPlayer({
                  variables: {
                    data: {
                      contentId: mediaItem.id,
                    },
                  },
                })
                if (player.data?.player?.signedUrl) {
                  dispatch(
                    playVideo({
                      src: player.data?.player?.signedUrl,
                      id: mediaItem.id,
                      position: liveNow ? undefined : completed ? -1 : mediaItem.viewer?.lastPosition,
                    }),
                  )
                }
              }
            }}
          />
        )
      )}
      {!onSlider && (
        <>
          {!mediaItem?.restrictions?.length && progress && !completed ? (
            <RoundButton
              type={'replay'}
              onClick={async () => {
                if (mediaItem?.id) {
                  if (isMobile) {
                    // must stop video for resetting it on iOS safari
                    dispatch(stopVideo())
                  }
                  const player = await fetchPlayer({
                    variables: {
                      data: {
                        contentId: mediaItem.id,
                      },
                    },
                  })
                  if (player.data?.player?.signedUrl) {
                    dispatch(
                      playVideo({
                        src: player.data?.player?.signedUrl,
                        id: mediaItem.id,
                        position: liveNow ? undefined : -1,
                      }),
                    )
                  }
                }
              }}
            />
          ) : null}
          {signedIn && <WatchlistButton mediaItem={mediaItem} />}
        </>
      )}
    </>
  )
}

export default ActionButtons
